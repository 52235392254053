export const CHAMPION_LOADING = 'CHAMPION_LOADING'
export const CLEAR_CHAMP_LOADING = 'CLEAR_CHAMP_LOADING'
export const SET_ROLE = 'SET_ROLE'
export const SET_INPUT = 'SET_INPUT'
export const GET_CHAMPION = 'GET_CHAMPION'
export const SET_CHAMPION = 'SET_CHAMPION'
export const SET_FADE_FALSE = 'SET_FADE_FALSE'
export const SET_FADE_TRUE = 'SET_FADE_TRUE'
export const SELECT_CHAMPION = 'SELECT_CHAMPION'
export const SELECT_CHAMPION_ERROR = 'SELECT_CHAMPION_ERROR'
export const SELECT_VIDEO = 'SELECT_VIDEO'
export const VIDEO_LOADING = 'VIDEO_LOADING'
export const NEXT_SKIN = 'NEXT_SKIN'
export const PREV_SKIN = 'PREV_SKIN'
export const SKIN_FADE = 'SKIN_FADE'
export const RESET_SKIN = 'RESET_SKIN'
export const RESET_CHAMPION_DETAIL = 'RESET_CHAMPION_DETAIL'
