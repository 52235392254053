//Input Reducer
export const SHOW_NAV = 'SHOW_NAV'
export const HIDE_NAV = 'HIDE_NAV'
export const BRAND_BACKGROUND = 'BRAND_BACKGROUND'
export const CHAMP_BACKGROUND = 'CHAMP_BACKGROUND'
export const SHOW_STORAGE = 'SHOW_STORAGE'
export const HIDE_STORAGE = 'HIDE_STORAGE'
export const ANIMATE_SHOW = 'ANIMATE_SHOW'
export const ANIMATE_HIDE = 'ANIMATE_HIDE'
export const ADD_SUMMONER = 'ADD SUMMONER'
export const REMOVE_SUMMONER = 'REMOVE_SUMMONER'
export const USER_INPUT = 'USER_INPUT'
export const SET_FADE = 'SET_FADE'
