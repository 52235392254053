// Leaderboard Reducer
export const LEADERBOARD_LOADING = 'LEADERBOARD_LOADING'
export const CLEAR_LEADERBOARD = 'CLEAR_LEADERBOARD'
export const LEADERBOARD_TOKEN = 'LEADERBOARD_TOKEN'
export const INCREMENT_PAGE = 'INCREMENT_PAGE'
export const DECREMENT_PAGE = 'DECREMENT_PAGE'
export const GET_LEADERBOARD = 'GET_LEADERBOARD'
export const LEADERBOARD_ERROR = 'LEADERBOARD_ERROR'
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
export const SET_POSTS_PER_PAGE = 'SET_POSTS_PER_PAGE'
export const SET_RANK = 'SET_RANK'
export const SET_PAGE = 'SET_PAGE'
