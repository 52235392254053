// SummonerInfo Reducer
export const GET_SUMMONER_INFO = 'GET_SUMMONER_INFO'
export const CLEAR_SUMMONER_INFO = 'CLEAR_SUMMONER_INFO'
export const CLEAR_SUMMONER_STATE = 'CLEAR_SUMMONER_STATE'
export const SUMMONER_INFO_LOADING = 'SUMMONER_INFO_LOADING'
export const SUMMONER_INFO_ERROR = 'SUMMONER_INFO_ERROR'
export const GET_MORE_MATCHES = 'GET_MORE_MATCHES'
export const MATCHES_LOADING = 'MATCHES_LOADING'
export const MATCHES_ERROR = 'MATCHES_ERROR'
export const GET_SUMMONER_REGION = 'GET_SUMMONER_REGION'
export const GET_TOKEN = 'GET_TOKEN'
export const CLEAR_SEARCH = 'CLEAR_SEARCH'
